import { graphql } from 'gatsby';
import * as React from 'react';

import { Button } from '@components/Button';
import { Wrapper } from '@components/Wrapper';

interface NotFoundPageProps {
	data: any;
}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = ({ data: { markdownRemark: { html } } }) => (
	<React.Fragment>
		<Wrapper>
			<Button type="button" role="primary" link="/">Zurück</Button>
		</Wrapper>
		<Wrapper>
			{/* eslint-disable-next-line @typescript-eslint/naming-convention */}
			<div dangerouslySetInnerHTML={{ __html: html }} />
		</Wrapper>
	</React.Fragment>
);

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
      }
    }
  }`;

export default NotFoundPage;
